import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import {ApiService} from './api.service';
import { Receiver } from '../home/receiver.model';

@Injectable({
  providedIn: "root",
})
export class ReceiverService {
  receivers: any[];
  currentUserId: string;
  constructor(private authService: AuthService, public afs: AngularFirestore) {
    this.currentUserId =  ApiService.getUser().id;
    //console.log(this.currentUserId);
    //this.getReceiversData();
  }

  getReceiversData() {
      this.currentUserId =  ApiService.getUser().id;
    //this.currentUserId = this.authService.currentUser;
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection("users")
        .doc(ApiService.getUser().id)
        .collection("receivers")
        .valueChanges({ idField: "id" })
        .subscribe(
          (snapshots) => {
            resolve(snapshots);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getReciver(id: string) {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection("users")
        .doc(this.currentUserId)
        .collection("receivers")
        .doc(id)
        .valueChanges()
        .subscribe(
          (snapshot) => {
            resolve(snapshot);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  setReceiverData(data: any[]) {
    this.receivers = data;
  }
  addReceiver(receiver: Receiver) {
    return firebase
      .firestore()
      .collection("users")
      .doc(this.currentUserId)
      .collection("receivers")
      .add(receiver);
  }
  deleteReceiver(id: string) {
    return firebase
      .firestore()
      .collection("users")
      .doc(this.currentUserId)
      .collection("receivers")
      .doc(id)
      .delete();
  }
}
