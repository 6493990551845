
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReceiverService } from '../service/receiver.service';
import { ApiService } from '../service/api.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { Receiver } from './receiver.model';
import { TranslateService } from '@ngx-translate/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

declare const window: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  model = {
    left: true,
    middle: false,
    right: false,
  };

  focus;
  focus1;
  beneficiares = [];

  amount: string;
  phoneNumber = '';
  cPhoneNumber = '';
  operators = [];
  country = 'Cameroun';
  receiverId: string;
  receiver: Receiver = {
    name: '',
    country: '',
    number: '',
    numberWithCode: '',
  };
  receiverList: any[] = [];
  fees = [];
  fee = 0;
  our_fees = 0;
  their_fees = 0;
  TotalSend = 0;
  currency = 'EUR';
  converted_amount = 0;
  in_progress = false;

  alert = null;

  reference = null;
  reference_ = null;
  operator = '';
  user = ApiService.getUser();
  clientId = '';
  tel: any;
  histories = [];
  simulation = {
    fee:0,
    amount:0,
    currency:'USD'
  }

  constructor(
    private receiverService: ReceiverService,
    private router: Router,
    public afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    public api: ApiService,
    public translate: TranslateService,
    private modalService: NgbModal
  ) {}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(result);
    });
  }


  async ngOnInit() {
    const $this = this;

    this.afs
      .collection('transactions', (req) =>
        req.where('user_id', '==', this.user.id).orderBy('timestamp', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((snapshots) => {
        this.histories = snapshots;
        // console.log(this.histories);
      });
    await setTimeout(async () => {
      this.beneficiares = await this.receiverService.getReceiversData();
      // console.log(this.receiverList);
      // this.user =JSON.parse(localStorage.getItem("cosna_user"));
      // console.log(this.user);
    }, 2000);
    // this.receiverList = this.receiverService.receivers;
    /*this.receiverService
                .getReceiversData()
                .toPromise()
                .then((receiver) => {
                    this.receiverList = receiver;
                })
                .catch((error) => //console.log(error));*/
    /*//console.log(firebase
                .auth().currentUser);
            this.user = await this.api.getUserById(firebase
                .auth().currentUser.uid);
            this.clientId = await this.api.performGetTextPromise("https://api.cosna-afrique.com/get_client_token.php?id="+this.user.customerId, null)

            //console.log(this.user);
            //console.log(this.clientId);*/
    this.afs
      .collection('cosna_fees')
      .valueChanges({ idField: 'dataId' })
      .subscribe((snapshots: any) => {

        console.log(snapshots);
        this.fees = snapshots;
      });
    this.activatedRoute.queryParams.subscribe(async (params) => {
      // console.log(params);
      if (params.pay != null) {
        const obj = JSON.parse(localStorage.getItem('details'));
        localStorage.removeItem('details');
        //console.log(obj);
        this.amount = obj.amount;
        this.converted_amount = obj.converted_amount;
        this.phoneNumber = obj.phoneNumber;
        this.cPhoneNumber = obj.phoneNumber;
        this.country = obj.country;
        this.currency = obj.currency;
        this.operator = obj.operator;
        this.receiverId = obj.receiverId;
        this.receiver = obj.receiver;
        this.fee = obj.fee;
        /*setTimeout(() => {
                    var input = document.querySelector('#phoneHome');
                    this.tel = window.intlTelInput(input, {
                        onlyCountries: ['cm', 'ci'],
                        utilsScript: 'assets/js/utils.js',
                    });
                    this.tel.setNumber('+' + obj.numberWithCode);
                }, 3000);*/
        // console.log(params.event);
        let coolpay_ = localStorage.getItem('coolpay');

        if (params.status == 'coolpay') {
          try {
            let res = await this.api.performGetPromise('https://my-coolpay.com/api/66c57bb6-f772-413d-a34f-d4b7c3d0bfc0/checkStatus/' + coolpay_, null);
            localStorage.removeItem('coolpay');
            if (res.transaction_status == 'SUCCESS') {
              this.doCoolpayDepot();
            } else {
              this.api.handleErrors(await this.translate.get('Une erreur s\'est produite lors du paiement.').toPromise());
            }
          } catch (e) {
            const updates = {
              status: 'PAYMENT_FAILED',
            };
            await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
                .then(
                    result => {
                      ////console.log(result);
                      // localStorage.setItem('cosna_id', result.id);
                      this.in_progress = false;
                      localStorage.removeItem('ref');
                      localStorage.removeItem('level');
                      localStorage.removeItem('cosna_id');
                      localStorage.removeItem('moneygo_depot_id');
                    },
                    err => {

                    }
                );
          }
        } else if (params.status == 'successful') {
          if (this.api.getOperator(this.operator, this.api.getCountry(this.country)).depositBy == 'paydunya') {
            this.doDunyaDepot();
          } else if (this.api.getOperator(this.operator, this.api.getCountry(this.country)).depositBy == 'dusupay') {
            this.doDusuDepot();
          } else {
            this.doDepot();
          }
        } else {
          this.api.handleErrors(await this.translate.get('Une erreur s\'est produite lors du paiement.').toPromise());

          const updates = {
            status: 'PAYMENT_FAILED',
          };
          await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
              .then(
                  result => {
                    ////console.log(result);
                    // localStorage.setItem('cosna_id', result.id);
                    this.in_progress = false;
                    localStorage.removeItem('ref');
                    localStorage.removeItem('level');
                    localStorage.removeItem('cosna_id');
                    localStorage.removeItem('moneygo_depot_id');
                  },
                  err => {

                  }
              );
        }

        // this.amount = params.amount - this.fee;
      }
    });
    if (this.route.snapshot.params['id']) {
      // console.log('id send');
      this.route.params.subscribe(async (param) => {
        this.receiverId = this.route.snapshot.params['id'];
        // console.log(' receiver id : ' + this.receiverId);
        await this.receiverService.getReciver(this.receiverId).then((data) => {
          this.phoneNumber = data?.number;
          this.country = data?.country;
          this.loadOperators();
          // console.log(this.phoneNumber + " =>> " + this.country);
        });
      });
    }
  }



  loadOperators() {
     console.log(this.country + 'receiver country');
    this.operators = this.api.getCountry(this.country).operators;
     console.log(this.operators);
  }

  async onSubmit() {
    /*if (!this.tel.isValidNumber()) {
            this.api.handleErrors('Numéro de téléphone invalide');
            return;
        }*/

    if ((this.currency == "EUR" || this.currency == "USD") && parseFloat(this.amount.toString()) < 10) {
      this.api.handleErrors(await this.translate.get('Le montant minimum est de 10 ').toPromise() + this.currency);
      return;
    }
    if ((this.currency == "XAF" || this.currency == "XOF") && parseFloat(this.amount.toString()) < 1000) {
      this.api.handleErrors(await this.translate.get('Le montant minimum est de 10 ').toPromise() + this.currency);
      return;
    }
    if ((this.currency == "EUR" || this.currency == "USD") && parseFloat(this.amount.toString()) > 1000) {
      this.api.handleErrors(await this.translate.get('Le montant maximum est de 1000 ').toPromise() + this.currency);
      return;
    }
    if ((this.currency == "XAF" || this.currency == "XOF") && parseFloat(this.amount.toString()) > 500000) {
      this.api.handleErrors(await this.translate.get('Le montant maximum est de 500000 ').toPromise() + this.currency);
      return;
    }
    if (this.phoneNumber.length == 0 || this.cPhoneNumber.length == 0) {
      this.api.handleErrors(await this.translate.get('Veuillez renseigner tous les champs.').toPromise());
      return;
    }
    if (this.phoneNumber !== this.cPhoneNumber) {
      this.api.handleErrors(await this.translate.get('Les deux numéros ne correspondent pas.').toPromise());
      return;
    }
    /*if (
      (this.country == "Cameroun" &&
        (this.api.isOrangeNumber(this.phoneNumber) ||
          this.api.isMtnNumber(this.phoneNumber))) ||
      (this.country == "Cote d'Ivoire" &&
        (this.api.isOrangeNumberCI(this.phoneNumber) ||
          this.api.isMtnNumberCI(this.phoneNumber)))
    ) {*/
      /*var countryData = this.tel.getSelectedCountryData();
            this.phoneNumber = this.tel
                .getNumber()
                .toString()
                .replace('+' + countryData.dialCode, '');
            //console.log(this.phoneNumber);*/

      const data = {
        amount: this.amount,
        phoneNumber: this.phoneNumber,
        country: this.country,
      };
      // console.log(data);
      this.fee = this.getFee();
      // const loading = await this.loadingController.create({
      //   cssClass: "my-custom-class",
      //   message: await this.translate.get("Veuillez patienter...").toPromise(),
      //   duration: 2000,
      // });
      // await loading.present();
      // console.log('before');
      this.api
        .performRemoteGETCall(
          'https://api.cosna-afrique.com/rates.php?amount=' +
            this.amount +
            '&from=' +
            this.currency +
            '&to=' +
            this.api.getCountry(this.country).currency
        )
        .subscribe(
          async (d) => {
            // loading.dismiss();
            // console.log('after');
            // console.log(d);
            this.converted_amount = parseFloat(d.data.to.amount.toString());
            Swal.fire({
              title: await this.translate.get('Confirmer').toPromise(),
              text:
                (await this.translate
                  .get('Vous êtes sur le points de transférer : ')
                  .toPromise()) +
                this.amount +
                ' ' +
                this.currency +
                ',  ' +
                (await this.translate
                  .get('Les frais pour ce transfert s\'élèvent à ')
                  .toPromise()) +
                d.data.to.amount +
                ' ' +
                d.data.to.currency +
                '. ' +
                (await this.translate
                  .get('continuez la transaction ? ')
                  .toPromise()) +
                ' ' +
                this.fee +
                ' ' +
                this.currency +
                ', ' +
                'continuez la transaction ? ',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: await this.translate.get('Oui').toPromise(),
              cancelButtonText: await this.translate.get('Non').toPromise(),
            }).then((result) => {
              if (result.value) {
                if (this.api.getCountry(this.country).payWith === 'paydunya') {
                  this.paydunyaPay();
                } else if (this.api.getCountry(this.country).payWith === 'dusupay') {
                  this.dusupayPay();
                } else if (this.api.getCountry(this.country).payWith == 'coolpay') {
                  this.coolpayPay();
                }
              }
            });
          },
          (err) => {}
        );
    /*} else {
      this.api.handleErrors(
        await this.translate
          .get("Numéro invalide pour le pays sélectionné!")
          .toPromise()
      );
    }*/
  }

  updateStatus2(status) {
    this.afs
      .collection('cosna_retrait')
      .doc(localStorage.getItem('cosna_id'))
      .update({
        status: status,
      })
      .then((res) => {});
  }

  getFee() {
    const f = 0;
    /*this.fees.forEach((fee) => {
      //&& fee.a == this.data.receiver_operator
      if (
        parseFloat(this.amount) >= fee.min &&
        parseFloat(this.amount) <= fee.max
      ) {
        if (fee.type == "POURCENT") {
          f = parseFloat(this.amount) * (fee.montant / 100);
        } else if (fee.type == "FIXE") {
          f = fee.montant;
        }
      }
    });*/
    const fee = this.fees[0];
    console.log(fee);
    const country = this.api.getCountry(this.country);
    if (this.country == 'cm') {
      this.our_fees = parseFloat(fee.cm_fees_type == 'FIXED' ? fee.cm_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.cm_fees).toFixed(2)));
    } else if (this.country == 'ci') {
      this.our_fees = parseFloat(fee.ci_fees_type == 'FIXED' ? fee.ci_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.ci_fees).toFixed(2)));
    } else if (this.country == 'sn') {
      this.our_fees = parseFloat(fee.sn_fees_type == 'FIXED' ? fee.sn_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.sn_fees).toFixed(2)));
    }
    //this.our_fees = parseFloat(((parseFloat(this.amount) / 100) * fee.montant).toFixed(2));
    this.their_fees = (country.feeType === 'percent' ? (parseFloat(this.amount) * (country.fee / 100)) : country.fee) + (parseFloat(this.amount) * ((country.payWith == 'dusupay' ? 0 : 3.5) / 100));
    return this.our_fees;
  }
  savePayment(details) {
    console.log(details);
    // if (details.transaction.processorResponseText.toLowerCase() == "approved") {
    const $this = this;
    const params: any = {};

    params.amount = parseFloat(this.amount);
    params.phoneNumber = this.phoneNumber;
    params.country = this.country;
    params.currency = this.currency;
    params.status = 'PENDING';
    params.receiverId = this.receiverId;
    params.receiver = this.receiver;
    params.fee = this.fee;
    params.user_id = this.user.id;
    params.reference_paypal = details.transaction.id;
    params.amount = params.amount - this.fee;
    params.date = new Date().toLocaleString();
    params.timestamp = new Date().getMilliseconds();
    this.afs
      .collection('transactions')
      .add(params)
      .then(
        (result) => {
          // console.log(result);
          localStorage.setItem('cosna_id', result.id);
        },
        (err) => {}
      );
    this.doDepot();
    // } else {
    // this.updateStatus2('FAILED');
    // this.api.handleErrors("Une erreur s'est produite lors du paiement");
    // this.utils.notify('danger', , 'top', 'right');
    // }
  }

  async initButton() {
    // const actionSheet = await this.actionSheetController.create({
    //   header: "Mode de paiement",
    //   cssClass: "my-custom-class",
    //   buttons: [
    //     {
    //       text: "PayPal",
    //       role: "destructive",
    //       icon: "logo-paypal",
    //       handler: () => {
    //         this.payPaypal();
    //       },
    //     },
    //     {
    //       text: "Carte de crédit",
    //       icon: "card",
    //       handler: () => {
    //         this.creditCardPay();
    //       },
    //     },
    //   ],
    // });
    // await actionSheet.present();
    /*
     */
  }

  // payPaypal() {
  //   const _this = this;

  //   this.payPal
  //     .init({
  //       PayPalEnvironmentProduction:
  //         "ATCpYmnNf2-eFa7meaovYqorD3i-yOBoDGczGadYSuC3XNeK-37PRnTq6XSfDkxDF9lkGqdzY7zIVfFt",
  //       PayPalEnvironmentSandbox:
  //         "AaAlFd2M7lK18eWak4Xk3W3fuMIKN8_cHu6PVpCfpY4OqdqiwjXCK9rwkjE4poih7q5-x0EWdOjXS38Z",
  //     })
  //     .then(
  //       () => {
  //         // Environments: PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
  //         this.payPal
  //           .prepareToRender(
  //             "PayPalEnvironmentProduction",
  //             new PayPalConfiguration({
  //               // Only needed if you get an "Internal Service Error" after PayPal login!
  //               //payPalShippingAddressOption: 2 // PayPalShippingAddressOptionPayPal
  //             })
  //           )
  //           .then(
  //             () => {
  //               let payment = new PayPalPayment(
  //                 (parseFloat(this.amount) + this.fee).toString(),
  //                 this.currency,
  //                 "Description",
  //                 "sale"
  //               );
  //               this.payPal.renderSinglePaymentUI(payment).then(
  //                 (res) => {
  //                   console.log(res);
  //                   // Successfully paid
  //                   _this.savePayment(res);

  //                   // Example sandbox response
  //                   //
  //                   // {
  //                   //   "client": {
  //                   //     "environment": "sandbox",
  //                   //     "product_name": "PayPal iOS SDK",
  //                   //     "paypal_sdk_version": "2.16.0",
  //                   //     "platform": "iOS"
  //                   //   },
  //                   //   "response_type": "payment",
  //                   //   "response": {
  //                   //     "id": "PAY-1AB23456CD789012EF34GHIJ",
  //                   //     "state": "approved",
  //                   //     "create_time": "2016-10-03T13:33:33Z",
  //                   //     "intent": "sale"
  //                   //   }
  //                   // }
  //                 },
  //                 () => {
  //                   // Error or render dialog closed without being successful
  //                 }
  //               );
  //             },
  //             () => {
  //               // Error in configuration
  //             }
  //           );
  //       },
  //       () => {
  //         // Error in initialization, maybe PayPal isn't supported or something else
  //       }
  //     );
  // }

  async doDepot() {
    // const loading = await this.loadingController.create({
    //   cssClass: "my-custom-class",
    //   message: "Initialisation du dépôt en cours...",
    //   duration: 2000,
    // });
    // await loading.present();

    const params: any = {
      operator_code:
        this.country == 'Cameroun'
          ? this.operator + '_CMR'
          : this.operator + '_CI',
      amount: this.converted_amount,
      number: this.phoneNumber.toString(), // this.phonenumber.toString(),
      token: '$2y$10$4FZyksOjMTbCPuQl1Nlc1OOFayX2Js7T/tk3QggASFg4g/7wf0U1y',
      have_prefix: false,
      client_reference: new Date().getTime().toString(),
      callback: 'https://api.moneygo.com/v1/payments',
    };
    // console.log(params);
    this.in_progress = true;
    this.api
      .performRemotePOSTCall(
        'https://www.view-pay.com/api/repo/cash/out',
        params
      )
      .subscribe(
        async (res) => {
          // loading.dismiss();
          // this.updateStatus2('PENDING');
          /*params.status = 'PENDING';
                params.receiverId = this.receiverId;
                params.user_id = this.user.id;
                params.reference_viewpay = res.data.reference;
                //params.retrait_id = localStorage.getItem('moneygo_id');
                params.date = new Date().toLocaleString();
                params.timestamp = new Date().getMilliseconds();*/

          const updates = {
            operator_code:
              this.country == 'Cameroun'
                ? this.operator + '_CMR'
                : this.operator + '_CI',
            amount: this.converted_amount,
            number: this.phoneNumber.toString(),
            status: 'PENDING',
            reference_viewpay: res.data.reference,
          };
          await this.afs
            .collection('transactions')
            .doc(localStorage.getItem('cosna_id'))
            .update(updates)
            .then(
              (result) => {
                // console.log(result);
                // localStorage.setItem('cosna_id', result.id);
              },
              (err) => {}
            );

          if (res.data.status == 'PENDING') {
            // this.alert = await this.alertController.create({
            //   cssClass: "my-custom-class",
            //   header: "Succès",
            //   message: "Dépôt vers le Cameroun en cours",
            //   buttons: ["OK"],
            // });

            Swal.fire({
              title: 'Success',
              text:
                (await this.translate.get('Dépôt vers le ').toPromise()) +
                this.country +
                (await this.translate.get(' en cours').toPromise()),
              timer: 2000,
              timerProgressBar: true,
            });

            // await this.alert.present();
            this.reference = res.data.reference;
            this.reference_ = params.client_reference;
            localStorage.setItem('ref', this.reference);
            localStorage.setItem('level', 'deposit');
            setTimeout(() => {
              this.checkDepotTransaction();
            }, 5000);
          } else {
            this.in_progress = false;
            this.updateStatus2('DEPOT_FAILED');
            localStorage.removeItem('cosna_id');
            localStorage.removeItem('moneygo_depot_id');
            localStorage.removeItem('ref');
            localStorage.removeItem('level');
            this.api.handleErrors(
              await this.translate.get('Une erreur s\'est produite!').toPromise()
            );
          }
          console.log(res);
        },
        (err) => {
          localStorage.removeItem('cosna_id');
          localStorage.removeItem('moneygo_depot_id');
          localStorage.removeItem('ref');
          localStorage.removeItem('level');
          console.log(err.error.message);
          // loading.dismiss();
          this.in_progress = false;
          this.api.handleErrors(err.error.message);
        }
      );
  }
  async checkDepotTransaction() {
    // const loading = await this.loadingController.create({
    //   cssClass: "my-custom-class",
    //   message: "Vérification du dépôt en cours...",
    //   duration: 2000,
    // });
    // await loading.present();

    Swal.fire({
      title: 'info',
      text: await this.translate
        .get('Vérification du dépôt en cours...')
        .toPromise(),
      timer: 2000,
      icon: 'info',
    });

    this.api
      .performRemotePOSTCall(
        'https://www.view-pay.com/api/repo/cashout/verify/' + this.reference,
        null
      )
      .subscribe(
        async (res) => {
          console.log(res);
          // loading.dismiss();
          // this.in_progress = true;
          if (res.code == 'TP200' || res.code == 'T200') {
            // "SUCCESS"
            if (res.data.status == 'PENDING' || res.data.status == 'WAITING') {
              setTimeout(() => {
                this.checkDepotTransaction();
              }, 5000);
            } else if (res.data.status == 'FAILURE') {
              this.in_progress = false;
              localStorage.removeItem('ref');
              localStorage.removeItem('level');
              this.updateStatus2('FAILED');
              this.saveFailOperation();
              localStorage.removeItem('cosna_id');

              localStorage.removeItem('moneygo_depot_id');
              this.api.handleErrors(
                await this.translate
                  .get(
                    await this.translate
                      .get('Transaction terminée avec succès')
                      .toPromise()
                  )
                  .toPromise()
              );
            } else {
              this.api.displayMessage('Transaction terminer avec succès');
              this.in_progress = false;
              localStorage.removeItem('ref');
              localStorage.removeItem('level');
              this.updateStatus2('SUCCESS');
              this.saveFailOperation();
              localStorage.removeItem('cosna_id');
              localStorage.removeItem('moneygo_depot_id');
              await this.alert.dismiss();
              // const alert = await this.alertController.create({
              //   cssClass: "my-custom-class",
              //   header: "Succès",
              //   message: "Transaction terminer avec succès",
              //   buttons: ["OK"],
              // });

              // await alert.present();
              // this.activeModal.dismiss('success');

              Swal.fire({
                title: 'Success',
                text: await this.translate
                  .get('Transaction terminée avec succès')
                  .toPromise(),
                icon: 'success',
              });
            }
          } else {
            this.api.handleErrors(res.message);
            this.in_progress = false;
            localStorage.removeItem('ref');
            localStorage.removeItem('level');
            this.updateStatus2('ERROR');
            this.saveFailOperation();
            localStorage.removeItem('cosna_id');
            localStorage.removeItem('moneygo_depot_id');
          }
          console.log(res);
        },
        (err) => {
          console.log(err);
          // loading.dismiss();
          this.in_progress = false;
          this.api.handleErrors(err.error.message);
          // this.in_progress = false;
          localStorage.removeItem('ref');
          localStorage.removeItem('level');
          this.updateStatus2('ERROR');
          this.saveFailOperation();
          localStorage.removeItem('cosna_id');
          localStorage.removeItem('moneygo_depot_id');
        }
      );
  }

  saveFailOperation() {
    const cosna_id = localStorage.getItem('cosna_id');
    return this.afs
      .collection('fails')
      .add({ cosna_id, date: new Date().toISOString(), status: 'pending' });
  }

  async onChange() {
    // alert("data changed");
    await this.receiverService.getReciver(this.receiverId).then((receiver) => {
      this.phoneNumber = receiver.number;
      this.country = receiver.country;
      //console.log(this.phoneNumber);
    });
    //console.log(`phone number : ${this.phoneNumber}, amount : ${this.country}`);
  }




  async doCoolpayDepot() {
    ////console.log("sending");

    const params: any = {
      operator_code: this.country == 'Cameroun' ? this.operator + '_CMR' : this.operator + '_CI',
      converted_amount: this.converted_amount,
      converted_currency: this.api.getCountry(this.country).currency,
      number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(), //this.phonenumber.toString(),
      token: '$2y$10$4FZyksOjMTbCPuQl1Nlc1OOFayX2Js7T/tk3QggASFg4g/7wf0U1y',
      have_prefix: false,
      client_reference: new Date().getTime().toString(),
      callback: 'https://api.moneygo.com/v1/payments'
    };
    ////console.log(params);
    this.in_progress = true;


    let flutter = {
      'private_key': 'dRhsqOlTQj3vRpHPys18jmprQPTjzQ9FYFSfDKZqMJuDlGd2gqGqba9TMYYd8KnK',
      'transaction_amount': Math.round(this.converted_amount),
      'transaction_reason': 'Transfer d\'argent',
      'transaction_operator': this.operator,
      'customer_phone_number': this.phoneNumber,
      'app_transaction_ref': new Date().getTime().toString() + this.phoneNumber,
    };
    this.api.performPostPromiseDusupay('https://my-coolpay.com/api/66c57bb6-f772-413d-a34f-d4b7c3d0bfc0/payout', flutter).then(async res => {

      const updates = {
        operator_code: this.operator,
        amount: Math.round(this.converted_amount),
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: res.status == 'success' ? 'SUCCESS' : 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      //console.log(res);
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                ////console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );
      this.in_progress = false;
      if (res.status == 'success') {

        Swal.fire({
          text: await this.translate.get('Transaction terminée avec succès').toPromise(),
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'OK',
        });
      } else {
        this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      }


    }, async err => {
      this.in_progress = false;
      this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      const updates = {
        operator_code: this.operator,
        amount: this.converted_amount,
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      //console.log(res);
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                ////console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );

    });
  }

  async doDunyaDepot() {
    //// console.log("sending");
    /*const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: await this.translate.get('Initialisation du dépôt en cours...').toPromise(),
      duration: 2000
    });
    await loading.present();*/

    const params: any = {
      operator_code: this.country == 'Cameroun' ? this.operator + '_CMR' : this.operator + '_CI',
      amount: this.converted_amount,
      number: this.phoneNumber.toString(), // this.phonenumber.toString(),
      token: '$2y$10$4FZyksOjMTbCPuQl1Nlc1OOFayX2Js7T/tk3QggASFg4g/7wf0U1y',
      have_prefix: false,
      client_reference: new Date().getTime().toString(),
      callback: 'https://api.moneygo.com/v1/payments'
    };
    //// console.log(params);
    this.in_progress = true;


    const flutter = {
      'account_alias': this.phoneNumber,
      'amount': Math.round(this.converted_amount),
      'withdraw_mode': this.operator,

      /*'account_bank': this.operator,
      'account_number': this.country != "237" ? this.api.getCountry(this.country).tel+""+this.phoneNumber : this.phoneNumber.toString(),
      'amount': this.converted_amount,
      'narration': 'Cosna Trnsfr',
      'currency': this.api.getCountry(this.country).currency,
      'reference': new Date().getTime().toString(),
      'callback_url': 'https://webhook.site/b3e505b0-fe02-430e-a538-22bbbce8ce0d',
      'debit_currency': 'XAF',
      'beneficiary_name': 'Cosna'*/
    };
    this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', flutter).then(async res => {
      // loading.dismiss();
      const updates = {
        operator_code: this.operator,
        converted_amount: this.converted_amount,
        converted_currency: this.api.getCountry(this.country).currency,
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: res.response_code == '00' ? 'SUCCESS' : 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                //// console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );
      this.in_progress = false;
      if (res.response_code == '00') {
        Swal.fire({
          text: await this.translate.get('Transaction terminée avec succès').toPromise(),
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'OK',
        });
      } else {
        this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      }


    }, async err => {
      this.in_progress = false;
      // loading.dismiss();
      this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      const updates = {
        operator_code: this.operator,
        amount: this.converted_amount,
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      // console.log(res);
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                //// console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );

    });
  }

  async doDusuDepot() {
    //// console.log("sending");
    /*const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: await this.translate.get('Initialisation du dépôt en cours...').toPromise(),
      duration: 2000
    });
    await loading.present();*/

    const params: any = {
      operator_code: this.country == 'Cameroun' ? this.operator + '_CMR' : this.operator + '_CI',
      amount: this.converted_amount,
      number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(), // this.phonenumber.toString(),
      token: '$2y$10$4FZyksOjMTbCPuQl1Nlc1OOFayX2Js7T/tk3QggASFg4g/7wf0U1y',
      have_prefix: false,
      client_reference: new Date().getTime().toString(),
      callback: 'https://api.moneygo.com/v1/payments'
    };
    //// console.log(params);
    this.in_progress = true;


    const flutter = {
      'amount': this.amount,
      'api_key': 'PUBK-202128fb065e9527f9312bcb1d87ad9d3',
      'currency': this.currency,
      'method': 'MOBILE_MONEY',
      'provider_id': this.operator,
      'account_number': this.api.getCountry(this.country).tel + '' + this.phoneNumber,
      'account_name': this.phoneNumber,
      'account_email': this.user.email,
      'merchant_reference': new Date().getTime().toString() + this.phoneNumber,
      'narration': 'Transfer d\'argent',

      /*'account_bank': this.operator,
      'account_number': this.country != "237" ? this.api.getCountry(this.country).tel+""+this.phoneNumber : this.phoneNumber.toString(),
      'amount': this.converted_amount,
      'narration': 'Cosna Trnsfr',
      'currency': this.api.getCountry(this.country).currency,
      'reference': new Date().getTime().toString(),
      'callback_url': 'https://webhook.site/b3e505b0-fe02-430e-a538-22bbbce8ce0d',
      'debit_currency': 'XAF',
      'beneficiary_name': 'Cosna'*/
    };
    this.api.performPostPromiseDusupay('https://api.dusupay.com/v1/payouts', flutter).then(async res => {
      // loading.dismiss();
      const updates = {
        operator_code: this.operator,
        converted_amount: this.converted_amount,
        converted_currency: this.api.getCountry(this.country).currency,
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: res.code == 202 ? 'SUCCESS' : 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      res.code = 202;
      // console.log(res);
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                //// console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );
      this.in_progress = false;
      if (res.code == 202) {
        Swal.fire({
          text: await this.translate.get('Transaction terminée avec succès').toPromise(),
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'OK',
        });
      } else {
        this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      }


    }, async err => {
      this.in_progress = false;
      // loading.dismiss();
      this.api.handleErrors(await this.translate.get('Transaction échoué!').toPromise());
      const updates = {
        operator_code: this.operator,
        amount: this.converted_amount,
        number: this.api.getCountry(this.country).tel + '' + this.phoneNumber.toString(),
        status: 'DEPOT_FAILED',
        reference_viewpay: new Date().getTime().toString(),
      };
      // console.log(res);
      await this.afs.collection('transactions').doc(localStorage.getItem('cosna_id')).update(updates)
          .then(
              result => {
                //// console.log(result);
                // localStorage.setItem('cosna_id', result.id);
                this.in_progress = false;
                localStorage.removeItem('ref');
                localStorage.removeItem('level');
                localStorage.removeItem('cosna_id');
                localStorage.removeItem('moneygo_depot_id');
              },
              err => {

              }
          );

    });
  }


  async paydunyaPay() {
    const params: any = {};

    /*var countryData = this.tel.getSelectedCountryData();
    this.phoneNumber = this.tel
        .getNumber()
        .toString()
        .replace('+' + countryData.dialCode, '');*/
    params.amount = parseFloat(this.amount);
    params.phoneNumber = this.phoneNumber;
    params.country = this.country;
    params.currency = this.currency;
    params.operator = this.operator;
    params.receiverId = this.receiverId;
    params.receiver = this.receiver;
    params.converted_amount = this.converted_amount;
    params.fee = this.fee;
    params.numberWithCode = this.phoneNumber;
    // params.amount = params.amount - this.fee;
    localStorage.setItem('details', JSON.stringify(params));
    const p = {
      amount: btoa((parseFloat(this.amount) + this.fee).toString()),
      currency: this.currency,
      email: this.user.email,
      name: this.user.name,
      phone: '+' + this.user.numberWithCode,
      /*pay_buttun_text: '',
      ref: this.api.makeid(7),
      successurl: 'https://api.cosna-afrique.com/goToApp.php',
      failureurl: 'https://api.cosna-afrique.com/goToApp.php'*/
    };

    const country = this.api.getCountry(this.country);
    const det = {
      'invoice': {
        'total_amount': (parseFloat(this.amount) + this.our_fees),
        'description': 'Transfer d\'argent'
      },
      'store': {
        'name': 'Cosna Afrique',
        'logo_url': 'https://www.cosna-afrique.com/wp-content/uploads/2020/10/logo-cosna.png',
        'website_url': 'https://cosna-afrique.com'
      },
      'actions': {
        'cancel_url': 'https://api.cosna-afrique.com/goToSite.php',
        'return_url': 'https://api.cosna-afrique.com/goToSite.php'
      }
    };
    console.log(det);
    /*const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: await this.translate.get('Veuillez patienter...').toPromise(),
      duration: 2000,
    });
    await loading.present();*/

    try {
      const amount_ = await this.api.performGetPromise('https://api.cosna-afrique.com/rates.php?amount=' + (parseFloat(this.amount) + this.our_fees) + '&from=' + this.currency + '&to=' + country.currency, null);
      det.invoice.total_amount = Math.round(amount_.data.to.amount);
      const payment = await this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/checkout-invoice/create', det);
      const $this = this;
      const params: any = {};

      params.amount = parseFloat(this.amount);
      params.phoneNumber = this.phoneNumber;
      params.country = this.country;
      params.currency = this.currency;
      params.status = 'PENDING';
      params.receiverId = this.receiverId ? this.receiverId : 0;
      params.receiver = this.receiver ? this.receiver : 0;
      params.fee = this.fee;
      params.user_id = this.user.id;
      params.reference_paydunya = payment.token;
      params.amount = det.invoice.total_amount;
      params.date = new Date().toLocaleString();
      params.timestamp = new Date().getMilliseconds();
      this.afs
          .collection('transactions')
          .add(params)
          .then(
              (result) => {
                // console.log(result);
                localStorage.setItem('cosna_id', result.id);
              },
              (err) => {
                // console.log(err);
              }
          );
      // loading.dismiss();
      /*cordova.InAppBrowser.open(
          payment.response_text,
          '_system',
          'location=yes'
      );*/


      location = payment.response_text;
      /*const win = window;
      win.open(
          payment.response_text,
          '_system',
          'newWindow',
          'height=300,width=300'
      );
      win.addEventListener(
          'message',
          function (response) {
            console.log('test', response.data);
            if (response.data === 'successful') {
              $this.doDunyaDepot();
            } else {
              $this.api.handleErrors('Une erreur s\'est produite lors du paiement.');
            }
          },
          false
      );*/
      // let browser = this.iab.create(payment.payment_url, '_system', 'hidden=yes,location=no');
    } catch (e) {
      // console.log(e);
      // loading.dismiss();
      this.api.handleErrors('Une erreur s\'est produite');
    }


  }

  async dusupayPay() {
    const params: any = {};

    /*var countryData = this.tel.getSelectedCountryData();
    this.phoneNumber = this.tel
        .getNumber()
        .toString()
        .replace('+' + countryData.dialCode, '');*/
    params.amount = parseFloat(this.amount);
    params.phoneNumber = this.phoneNumber;
    params.country = this.country;
    params.currency = this.currency;
    params.operator = this.operator;
    params.receiverId = this.receiverId;
    params.receiver = this.receiver;
    params.converted_amount = this.converted_amount;
    params.fee = this.fee;
    params.numberWithCode = this.phoneNumber;
    // params.amount = params.amount - this.fee;
    localStorage.setItem('details', JSON.stringify(params));
    const p = {
      amount: btoa((parseFloat(this.amount) + this.fee).toString()),
      currency: this.currency,
      email: this.user.email,
      name: this.user.name,
      phone: '+' + this.user.numberWithCode,
      /*pay_buttun_text: '',
      ref: this.api.makeid(7),
      successurl: 'https://api.cosna-afrique.com/goToApp.php',
      failureurl: 'https://api.cosna-afrique.com/goToApp.php'*/
    };

    const det = {
      'api_key': 'PUBK-202128fb065e9527f9312bcb1d87ad9d3',
      'currency': this.currency,
      'amount': (parseFloat(this.amount) + this.fee),
      'method': 'CARD',
      'provider_id': 'international_' + this.currency.toString().toLowerCase(),
      'redirect_url': 'https://api.cosna-afrique.com/goToSite.php',
      'merchant_reference': new Date().getMilliseconds() + '' + this.phoneNumber,
      'narration': 'Transfer d\'argent'
    };
    /*const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: await this.translate.get('Veuillez patienter...').toPromise(),
      duration: 2000,
    });
    await loading.present();*/

    try {
      const country = this.api.getCountry(this.country);
      const amount_ = await this.api.performGetPromise('https://api.cosna-afrique.com/rates.php?amount=' + (parseFloat(this.amount) + this.fee) + '&from=' + this.currency + '&to=' + country.currency, null);
      // det.invoice.total_amount = amount_.data.to.amount; //Math.round();
      const payment = await this.api.performPostPromise('https://api.dusupay.com/v1/collections', det);
      const $this = this;
      const params: any = {};

      params.amount = parseFloat(this.amount);
      params.phoneNumber = this.phoneNumber;
      params.country = this.country;
      params.currency = this.currency;
      params.status = 'PENDING';
      params.receiverId = this.receiverId ? this.receiverId : 0;
      params.receiver = this.receiver ? this.receiver : 0;
      params.fee = this.fee;
      params.user_id = this.user.id;
      params.reference_dusupay = payment.data.internal_reference;
      params.amount = det.amount;
      params.date = new Date().toLocaleString();
      params.timestamp = new Date().getMilliseconds();
      this.afs
          .collection('transactions')
          .add(params)
          .then(
              (result) => {
                // console.log(result);
                localStorage.setItem('cosna_id', result.id);
              },
              (err) => {
                // console.log(err);
              }
          );
      // loading.dismiss();
      /*cordova.InAppBrowser.open(
          payment.payment_url,
          '_system',
          'location=yes'
      );*/


      const win = window;
      location = payment.payment_url;
      /*win.open(
          payment.payment_url,
          '_system',
          'newWindow',
          'height=300,width=300'
      );
      win.addEventListener(
          'message',
          function (response) {
            console.log('test', response.data);
            if (response.data === 'successful') {
              $this.doDusuDepot();
            } else {
              $this.api.handleErrors('Une erreur s\'est produite lors du paiement.');
            }
          },
          false
      );*/
      // this.iab.create(payment.payment_url, '_system', 'hidden=yes,location=no');
    } catch (e) {
      // console.log(e);
      // loading.dismiss();
      this.api.handleErrors('Une erreur s\'est produite');
    }


  }



  async coolpayPay() {
    let params: any = {};

    /*var countryData = this.tel.getSelectedCountryData();
    this.phoneNumber = this.tel
        .getNumber()
        .toString()
        .replace('+' + countryData.dialCode, '');*/
    params.amount = parseFloat(this.amount);
    params.phoneNumber = this.phoneNumber;
    params.country = this.country;
    params.currency = this.currency;
    params.operator = this.operator;
    params.receiverId = this.receiverId;
    params.receiver = this.receiver;
    params.converted_amount = this.converted_amount;
    params.fee = this.fee;
    params.numberWithCode = this.phoneNumber;
    //params.amount = params.amount - this.fee;
    localStorage.setItem('details', JSON.stringify(params));
    const p = {
      amount: btoa((parseFloat(this.amount) + this.fee).toString()),
      currency: this.currency,
      email: this.user.email,
      name: this.user.name,
      phone: '+' + this.user.numberWithCode,
      /*pay_buttun_text: '',
      ref: this.api.makeid(7),
      successurl: 'https://api.cosna-afrique.com/goToApp.php',
      failureurl: 'https://api.cosna-afrique.com/goToApp.php'*/
    };

    let country = this.api.getCountry(this.country);
    let amount_ = await this.api.performGetPromise('https://api.cosna-afrique.com/rates.php?amount=' + (parseFloat(this.amount) + this.our_fees) + '&from=' + this.currency + '&to=' + country.currency, null);
    const det = {
      'transaction_amount': Math.round(amount_.data.to.amount),
      'app_transaction_ref': new Date().getMilliseconds() + '' + this.phoneNumber,
      'transaction_reason': 'Transfer d\'argent'
    };

    try {
      //det.invoice.total_amount = amount_.data.to.amount; //Math.round(amount_.data.to.amount);
      const payment = await this.api.performPostPromise('https://my-coolpay.com/api/66c57bb6-f772-413d-a34f-d4b7c3d0bfc0/paylink?from=web', det);
      const $this = this;
      let params: any = {};

      localStorage.setItem('coolpay', payment.transaction_ref);
      params.amount = parseFloat(this.amount);
      params.phoneNumber = this.phoneNumber;
      params.country = this.country;
      params.currency = this.currency;
      params.status = 'PENDING';
      params.receiverId = this.receiverId ? this.receiverId : 0;
      params.receiver = this.receiver ? this.receiver : 0;
      params.fee = this.fee;
      params.user_id = this.user.id;
      params.reference_dusupay = payment.transaction_ref;
      params.amount = det.transaction_amount;
      params.date = new Date().toLocaleString();
      params.timestamp = new Date().getMilliseconds();
      this.afs
          .collection('transactions')
          .add(params)
          .then(
              (result) => {
                //console.log(result);
                localStorage.setItem('cosna_id', result.id);
              },
              (err) => {
                //console.log(err);
              }
          );
      location = payment.payment_url;
      /*window.open(
          payment.payment_url,
          '_system',
          'location=yes'
      );*/
      //this.iab.create(payment.payment_url, '_system', 'hidden=yes,location=no');
    } catch (e) {
      console.log(e);
      this.api.handleErrors('Une erreur s\'est produite');
    }


  }

  async creditCardPay() {
    const p = {
      amount: btoa((parseFloat(this.amount) + this.fee).toString()),
      currency: this.currency,
      email: this.user.email,
      name: this.user.name,
      phone: '+' + this.user.numberWithCode,
      /*pay_buttun_text: '',
            ref: this.api.makeid(7),
            successurl: 'https://api.cosna-afrique.com/goToApp.php',
            failureurl: 'https://api.cosna-afrique.com/goToApp.php'*/
    };

    const win = window;
    const $this = this;
    console.log('https://api.cosna-afrique.com/pay.php?' +
    this.api.serialize(p) + '&from_web=true');
    document.getElementById('overlay').style.display = 'block';
    win.open(
      'https://api.cosna-afrique.com/pay.php?' +
        this.api.serialize(p) + '&from_web=true',
      '_system',
      'newWindow',
      'height=300,width=300'
    );
    win.addEventListener(
      'message',
      async function c(response) {
        console.log('test', response.data);
        if (response.data === 'successful') {
          $this.doDepot();
          document.getElementById('overlay').style.display = 'none';
        } else {
          document.getElementById('overlay').style.display = 'none';
          $this.api.handleErrors(await $this.translate
            .get('Une erreur s\'est produite lors du paiement.')
            .toPromise());

        }
      },
      false
    );
    /*try {
            const payment = await this.api.performPostPromiseToken('https://api.flutterwave.com/v3/payments', det, 'FLWSECK-5ef346715900e04297604cfcbc9b94ea-X');
            window.open(
                payment.data.link,
                '_system'
            );
        } catch (err) {
            //console.log(err);
            this.api.handleErrors('Une erreur s\'est produite');
        }*/
    /*window.open(
            'https://flutterwave.com/pay/cosna-afrique?amount=' +
            (parseFloat(this.amount) + this.fee).toString() +
            '&email=' +
            this.user.email,
            '_system'
        );*/
    /*const lab_key = 'XT7zuounWNKXmbwdAR+qYhyQymRdsEUylXFZ/frwBBjDKZsPCDlUjAMH4OQT+uvU';
            const loading = await this.loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Initialisation de la transaction en cours...',
                duration: 2000
            });
            await loading.present();
            //Step 1 get token
            const token = await this.api.performPostPromise('https://developer.ecobank.com/corporateapi/user/token', {
                userId: 'iamaunifieddev103',
                password: '$2a$10$Wmame.Lh1FJDCB4JJIxtx.3SZT0dP2XlQWgj9Q5UAGcDLpB0yRYCC'
            }).catch(err => {
                //console.log(err);
                this.api.handleErrors('Une erreur s\'est produite');
            });

            const params = {
                'paymentDetails': {
                    'requestId': this.api.makeid(6),
                    'productCode': 'TRCOSNA',
                    'amount': (parseFloat(this.amount) + this.fee).toString(),
                    'currency': this.currency,
                    'locale': 'en_AU',
                    'orderInfo': this.api.makeid(6),
                    'returnUrl': 'cosna://home'
                },
                'merchantDetails': {
                    'accessCode': '79742570',
                    'merchantID': 'ETZ001',
                    'secureSecret': 'sdsffd'
                },
                'secureHash': '7f137705f4caa39dd691e771403430dd23d27aa53cefcb97217927312e77847bca6b8764f487ce5d1f6520fd7227e4d4c470c5d1e7455822c8ee95b10a0e9855'
            };
            const payment = await this.api.performPostPromiseToken('https://developer.ecobank.com/corporateapi/merchant/card', params, token.token).catch(err => {
                //console.log(err);
                this.api.handleErrors('Une erreur s\'est produite');
            });
            */
  }


  async onAddReceiver() {
    this.receiverService.addReceiver(this.receiver).then(() => {
      this.modalService.dismissAll();
      this.api.displayMessage('Receiver addedd successfully.');

    });
    this.beneficiares = await this.receiverService.getReceiversData();
  }


    onSend(id: string) {
      this.router.navigate(['/home', id]);
    }
    onDelete(id: string) {
      Swal.fire({
        text: 'Do you really want to delete?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: 'red',
      }).then((result) => {
        if (result.isConfirmed) {
          this.receiverService.deleteReceiver(id).then(async() => {
            this.beneficiares = await this.receiverService.getReceiversData();
            this.api.displayMessage('Deleted successfully');
          });
        }
      });
    }
    onCloseOverlay() {
      document.getElementById('overlay').style.display = 'none';
    }
}
