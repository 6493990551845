import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';


declare const Date: any;

@Component({
    selector: 'app-stats3',
    templateUrl: './stats3.component.html',
    styleUrls: ['./stats3.component.css']
})
export class Stats3Component implements OnInit {

    new_rate;
    new_rate_usd;

    data_curr_eur = 0;
    data_curr_usd = 0;


    charge_gains = 0;
    gain_eur = 0;
    gain_usd = 0;

    gains_v1 = 0;
    transfered_v1 = 0;
    fees_v1 = 0;
    pending_v1 = 0;

    gains_v2 = 0;
    transfered_v2 = 0;
    fees_v2 = 0;
    pending_v2 = 0;

    fedapay_pending = 0;
    fedapay_pending_data = [];
    fedapay_pending_fees = 0;

    //Fedapay : 44
    // Paydunya : 3

    base_url = 'https://api.payment.cosna-afrique.com/v1/';


    date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    date2 = this.datePipe.transform(new Date(), 'yyyy-MM-dd');//"2021-12-03";
    dates = ['2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07', '2021-12-08', '2021-12-09', '2021-12-10', '2021-12-11', '2021-12-12'];
    res: any = [];

    histories = [];
    data = [];

    our_eur_rates = 0;
    our_usd_rates = 0;

    constructor(public afs: AngularFirestore,
                public api: ApiService,
                public datePipe: DatePipe,
                private http: HttpClient) {
    }

    async ngOnInit() {
        this.our_eur_rates = (await this.api.performGetPromise("https://api.cosna-afrique.com/rates.php?from=EUR&to=XOF&amount=1", null)).data.to.amount;
        this.our_usd_rates = (await this.api.performGetPromise("https://api.cosna-afrique.com/rates.php?from=USD&to=XOF&amount=1", null)).data.to.amount;
        console.log(this.our_eur_rates);
        console.log(this.our_usd_rates);


        const new_rate = await this.api.performGetPromise('https://api.fastforex.io/convert?from=EUR&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
        this.new_rate = new_rate.result.rate;
        console.log(new_rate);
        const new_rate_usd = await this.api.performGetPromise('https://api.fastforex.io/convert?from=USD&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
        this.new_rate_usd = new_rate_usd.result.rate;
        console.log(new_rate_usd);
        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        //this.calcGainsv1();
        this.getFedaPayTransactions();
        this.calcGainsv2();
        setInterval(() => {
            /*this.pending_v1 = 0;
            var nodesSnapshot :any = document.evaluate("//tr[@style='background: red; display: table-row;']/td[@class='amount']", document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null );
            for ( var i=0 ; i < nodesSnapshot.snapshotLength; i++ ){
                this.pending_v1 += parseInt( nodesSnapshot.snapshotItem(i).innerText );

            }

            this.fees_v1 = this.pending_v1 * 0.008;
            console.log(this.pending_v1);*/
            this.calcGainsv2();
        }, 30000);
    }

    async getFedaPayTransactions() {
        //this.dates.forEach(async dte => {

        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';operator_id:44;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
        this.fedapay_pending = 0;
        this.fedapay_pending_data = snapshots.data;
        snapshots.data.forEach(async (d: any) => {
            this.fedapay_pending += Math.round(parseFloat(d.converted_amount));
        });
        this.fedapay_pending_fees = Math.round(0.003 * this.fedapay_pending);
        console.log(this.fedapay_pending);
        console.log(this.fedapay_pending_fees);
        console.log(this.fedapay_pending_data);


//        });
    }

    async fedaPayDoDepot() {
        //benificiary.data.name, benificiary.data.numberWithCode
        try {

            for (const transaction of this.fedapay_pending_data) {
                let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
                    'amount': transaction.converted_amount,
                    'currency': {'iso': 'XOF'},
                    'mode': 'mtn',
                    'customer': {
                        'firstname': transaction.benificiary.data.name,
                        'lastname': transaction.benificiary.data.name,
                        'email': transaction.user.data.email,
                        'phone_number': {
                            'number': transaction.benificiary.data.numberWithCode,
                            'country': 'bj'
                        }
                    }
                });
                let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
                    'payouts': [
                        {'id': depot_request['v1/payout'].id}
                    ]
                });
                let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
                    status: 'SUCCESS',
                    transaction_id: depot_request['v1/payout'].id
                });
                console.log("send successfully : ", transaction);
            }

        } catch (e) {
            console.log(e);
            this.api.handleErrors("une erreur s'est produit");
        }

    }

    async calcGainsv1() {
        console.log(this.date);
        this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                this.transfered_v1 = 0;
                snapshots.forEach(async (d: any) => {
                    //this.gains += parseFloat(d.converted_amount);
                    this.transfered_v1 += parseFloat(d.converted_amount);
                });
                this.gains_v1 = Math.round(0.015 * this.transfered_v1);
                // console.log(this.histories);
            });

        //this.pending_v1 = 0;
        let snapshots = await this.api.getTransactions(this.date);
        snapshots.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            d.paydunya_status = paydunya_response?.status;

        });
        this.histories = snapshots;
        /*snapshots.forEach((d:any) => {
            if(d.paydunya_status == "completed" && d.status != 'SUCCESS')
                this.pending_v1 += parseInt(d.converted_amount);
        });
        this.fees_v1 = this.pending_v1 * 0.008;*/

    }


    async calcGainsv2_new() {
        console.log(this.date2);
        this.data = [];
        this.dates.forEach(async dte => {
            let dd = {
                transfered: 0,
                gains: 0,
                pending: 0,
                fees: 0,
                date: dte
            };
            let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dte + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
            dd.transfered = 0;
            snapshots.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                dd.transfered += Math.round(parseFloat(d.converted_amount));
            });
            dd.gains = Math.round(0.015 * dd.transfered);

            //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
            let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + dte + '&pending=true&limit=0');
            dd.pending = 0;
            snapshots2.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                dd.pending += Math.round(parseFloat(d.converted_amount));
            });
            dd.fees = Math.round(0.001 * dd.pending);
            this.data.push(dd);
        });

        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
              this.transfered = 0;
              snapshots.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered += parseFloat(d.converted_amount);
              });
              this.gains = Math.round(0.022 * this.transfered);
              // console.log(this.histories);
            });*/
    }

    async getData(): Promise<any> {
        /*return new Promise<any>(async (resolve, reject) => {
            let snapshots_eur = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:EUR&limit=0&searchJoin=and&searchFields=status:=");
            let snapshots_usd = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:USD&limit=0&searchJoin=and&searchFields=status:=");
            let data = {
                eur : 0,
                usd : 0,
            };
            snapshots_eur.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                data.eur += Math.round(parseFloat(d.converted_amount));
            });
            snapshots_usd.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                data.usd += Math.round(parseFloat(d.converted_amount));
            });
            resolve(data);
        });*/
    }

    async calcRateGains() {
        //let data:any = await this.api.performGetPromise("https://api.fastforex.io/convert?from=XAF&to=EUR&amount="+this.transfered_v2+"&api_key=d1e58e5969-fc3ca7754a-r5x7zz", null);
        //console.log(this.new_rate);

        //let data = await this.getData();
        //this.charge_gains = Math.round((data.eur/650) * (this.new_rate - 650)) + Math.round((data.usd/520) * (this.new_rate_usd - 520)) ;
    }

    async calcGainsv2() {
        console.log(this.date2);
        this.dates.forEach(async dte => {

            let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
            this.transfered_v2 = 0;
            this.data_curr_eur = 0;
            this.data_curr_usd = 0;
            snapshots.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered_v2 += Math.round(parseFloat(d.converted_amount));
                if (d.currency == 'EUR') {
                    this.data_curr_eur += Math.round(parseFloat(d.converted_amount));
                }
                if (d.currency == 'USD') {
                    this.data_curr_usd += Math.round(parseFloat(d.converted_amount));
                }
            });
            //console.log("eur",this.data_curr_eur);
            //console.log("eur_rate",(this.new_rate - 650));
            //console.log("usd",this.data_curr_usd);
            //console.log("usd_rate",(this.new_rate_usd - 520));
            this.charge_gains = Math.round((this.data_curr_eur / this.our_eur_rates) * (this.new_rate - this.our_eur_rates)) + Math.round((this.data_curr_usd / this.our_usd_rates) * (this.new_rate_usd - this.our_usd_rates));
            this.gain_eur = Math.round((this.data_curr_eur / this.our_eur_rates) * (this.new_rate - this.our_eur_rates));
            this.gain_usd = Math.round((this.data_curr_usd / this.our_usd_rates) * (this.new_rate_usd - this.our_usd_rates));
            this.gains_v2 = Math.round(0.025 * this.transfered_v2);

            //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
            let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + this.date2 + '&pending=true&limit=0');
            this.pending_v2 = 0;
            snapshots2.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.pending_v2 += Math.round(parseFloat(d.converted_amount));
            });
            this.fees_v2 = Math.round(0.005 * this.pending_v2);


        });
        //this.calcRateGains();

        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
              this.transfered = 0;
              snapshots.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered += parseFloat(d.converted_amount);
              });
              this.gains = Math.round(0.022 * this.transfered);
              // console.log(this.histories);
            });*/
    }


    numberWithSpaces(x) {
        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }


    //SERVICE

    performGetPromise(url) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPATCHPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.patch(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPUTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.put(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }
}
