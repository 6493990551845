import { Component, OnInit } from '@angular/core';
import { AuthService } from './../service/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  email: string;
  phoneNumber: string;
  password: string;
  error: string;
  constructor(
    private router: Router,
    public api: ApiService,
    private authService: AuthService
  ) {
    if (ApiService.getUser() != null) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {}

  async onSubmit() {
    console.log('submited');
    const $this = this;
    const res = await this.authService
      .signIn(this.phoneNumber, this.password)
      .catch((err) => {
        // loading.dismiss();
        this.api.handleErrors(err.message);
        // this.errorMessage = err.message;
        console.log(err);
      });
    console.log(res);
    if (res.user) {
      const user = await this.authService.getUser(res.user.uid).catch((err) => {
        // loading.dismiss();
        this.api.handleErrors(err.message);
        // this.errorMessage = err.message;
        console.log(err);
        return;
      });
      user.id = res.user.uid;
      console.log('user', user);
      // if (user.length == 1) {
      delete user.password;
      localStorage.setItem('cosna_user', JSON.stringify(user));
      // loading.dismiss();
      $this.router.navigate(['/home']);
      // }
      // loading.dismiss();
    }
  }
}
