import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'app-stats2',
    templateUrl: './stats2.component.html',
    styleUrls: ['./stats2.component.css']
})
export class Stats2Component implements OnInit {
    histories: any[];
    xof_balance = 0;
    xaf_balance = 0;
    users = [];
    res = [];
    show_all = true;

    is_sending = false;

    amount1 = 0;
    amount2 = 0;
    date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    id: any;
    country: any = 'bj';
    phoneNumber: any;
    converted_amount: any;
    operator: any = 'mtn-benin';
    reference_paydunya: any;

    gains = 0;
    transfered = 0;
    fees = 0;
    pending = 0;

    ref = null;
    ref2 = null;

    page = 1;
    total_page = 0;
    page_for = 'all';


    base_url = 'https://api.payment.cosna-afrique.com/v1/';
    ref3 = null;
    ref4 = null;
    filter: any;

    constructor(
        public afs: AngularFirestore,
        public api: ApiService,
        public datePipe: DatePipe,
        private http: HttpClient
    ) {
    }

    async ngOnInit() {
        let fileds = [];
        this.load();

        //this.calcGains();


        let arr = [];
        this.res = [];
        let sele = '';
        arr.forEach(async a => {
            //sele += "select * from `transactions` where `transaction_id`='"+a+"';";
            setTimeout(async () => {
                    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=transaction_id:' + a + '&searchFields=transaction_id:=');
                    if (snapshots.data.length > 0) {
                        const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${snapshots.data[0].reference_paydunya}`);
                        this.res.push({'id': a, 'found': snapshots.data[0].reference_paydunya, 'status': paydunya_response?.status});
                    } else {
                        this.res.push({'id': a, 'found': false});
                    }
                },
                10000);

            /*this.afs
                .collection('transactions', req => req.where('transaction_id', '==', a),
                )
                .valueChanges()
                .subscribe((snapshots: any) => {
                      console.log(snapshots);
                      if(snapshots.length > 0)
                        this.res.push({"id" : a, "found": true});
                      else
                        this.res.push({"id" : a, "found": false});
                    }
                );*/
        });
        console.log(this.res);
        console.log(arr.length);
        console.log(sele);
    }

    async calcGains() {
        console.log(this.date);

        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
        this.transfered = 0;
        snapshots.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered += Math.round(parseFloat(d.converted_amount));
        });
        this.gains = 0;//Math.round(0.025 * this.transfered);

        let snapshots2 = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date + ';status:DEPOT_FAILED&limit=0&searchJoin=and&searchFields=status:=');
        this.pending = 0;
        snapshots2.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.pending += Math.round(parseFloat(d.converted_amount));
        });
        this.fees = Math.round(0.005 * this.pending);
        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
              this.transfered = 0;
              snapshots.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered += parseFloat(d.converted_amount);
              });
              this.gains = Math.round(0.022 * this.transfered);
              // console.log(this.histories);
            });*/
    }

    async search(filter, starting = true) {
        let snapshots: any = null;
        console.log('new version');
        this.filter = filter;
        if (filter == 'id') {
            this.page_for = 'id';
            snapshots = await this.performGetPromise(this.base_url + 'transactions/' + this.ref + '?include=user');
            snapshots.data = [snapshots.data];
        } else if (filter == 'user_id') {
            this.page_for = 'user';
            if (starting) {
                this.page = 1;
            }
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=user_id:' + this.ref3 + '&searchFields=user_id:=&page=' + this.page + '&limit=50');
            this.total_page = snapshots.meta.pagination.total_pages;
        } else if (filter == 'reference_paydunya') {
            this.page_for = 'reference';
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=paydunya_reference:' + this.ref2 + '&searchFields=paydunya_reference:like');
        } else {
            console.log(this.ref4);
            this.page_for = 'depot_reference';
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=transaction_id:' + this.ref4 + '&searchFields=transaction_id:like');
        }
        console.log(snapshots);
        snapshots.data.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);
            d.paydunya_status2 = paydunya_response?.status;
        });
        this.histories = snapshots.data;
    }

    async load(all = true) {


        this.calcGains();

        this.page_for = 'all';
        //&orderBy=updated_at&sortedBy=desc
        let snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=created_at:' + this.date + '&limit=50&page=' + this.page + '&orderBy=updated_at&sortedBy=desc');
        console.log(snapshots);
        this.total_page = snapshots.meta.pagination.total_pages;
        //let snapshots = await this.api.getTransactions(this.date);
        snapshots.data.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            d.paydunya_status2 = paydunya_response?.status;
        });
        this.histories = snapshots.data;
        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                //.where('user_id', '==', 'RdmgvgM03MO4BnSHgYVh7YRwFIw2')
                    .orderBy('timestamp', 'desc')
            )
            .valueChanges({idField: 'id'})
            .toPromise().then((snapshots) => {
                console.log(snapshots);
                snapshots.forEach(async (d: any) => {
                    const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

                    //console.log(paydunya_response);
                    //console.log(paydunya_response?.status);
                    d.paydunya_status = paydunya_response?.status;
                });
                this.histories = snapshots;
                // console.log(this.histories);
            });*/
    }

    numberWithSpaces(x) {
        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }

    setAllToSuccess() {
        this.afs
            .collection('users', (req) =>
                req.where('canSend', '==', true)
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                //this.histories = snapshots;
                // console.log(this.histories);
                snapshots.forEach(async (d: any) => {
                    await this.afs.collection('users').doc(d.id).update({
                        canSend: false
                    }).then(() => {
                        console.log('done');
                    });

                });
            });
    }

    async changestatus(id) {
        await this.afs.collection('users').doc(id).update({
            canResend: 'no'
        }).then(() => {
            console.log('done');
        });
    }


    private async doDunyaDepot(id, country, phoneNumber, converted_amount, operator) {
        this.is_sending = true;

        let flutter = {
            'account_alias': phoneNumber,
            'amount': Math.round(converted_amount),
            'withdraw_mode': operator,

            /*'account_bank': this.operator,
            'account_number': this.country != "237" ? this.api.getCountry(this.country).tel+""+this.phoneNumber : this.phoneNumber.toString(),
            'amount': this.data.converted_amount,
            'narration': 'Cosna Trnsfr',
            'currency': this.api.getCountry(this.country).currency,
            'reference': new Date().getTime().toString(),
            'callback_url': 'https://webhook.site/b3e505b0-fe02-430e-a538-22bbbce8ce0d',
            'debit_currency': 'XAF',
            'beneficiary_name': 'Cosna'*/
        };
        //if (operator != "mtn") {
            this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', flutter).then(async res => {


                ////this.in_progress = false;
                if (res.response_code == '00') {

                    let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + this.id, {
                        status: 'SUCCESS',
                        transaction_id: res?.transaction_id,
                        provider_ref: res?.provider_ref,
                        phoneNumber: phoneNumber.toString(),
                    });
                    console.log(snapshots);
                    this.is_sending = false;
                    if (this.page_for == 'all') {
                        console.log('all');
                        this.load();
                    } else {
                        console.log('filter');
                        this.search(this.filter);
                        alert('Transaction terminée avec succès');
                    }

                } else {
                    this.is_sending = false;
                    alert('Transaction échoué!');
                }


            }, async err => {

                this.is_sending = false;

                this.api.handleErrors('Transaction échoué!');

            });
        /*} else {
            this.fedaPayDoDepot(id, country, phoneNumber, converted_amount, operator);
        }*/
    }


    async fedaPayDoDepot(id, country, phoneNumber, converted_amount, operator) {
        //benificiary.data.name, benificiary.data.numberWithCode
        try {
            let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
                'amount': converted_amount,
                'currency': {'iso': 'XOF'},
                'mode': 'mtn',
                'customer': {
                    'firstname': '',
                    'lastname': '',
                    'email': '',
                    'phone_number': {
                        'number': '+237' + phoneNumber,
                        'country': 'bj'
                    }
                }
            });
            let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
                'payouts': [
                    {'id': depot_request['v1/payout'].id}
                ]
            });
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + id, {
                status: 'SUCCESS',
                phoneNumber: phoneNumber.toString(),
                transaction_id: depot_request['v1/payout'].id
            });
            this.is_sending = false;
            if (this.page_for == 'all') {
                console.log('all');
                this.load();
            } else {
                console.log('filter');
                this.search(this.filter);
                alert('Transaction terminée avec succès');
            }


        } catch (e) {
            this.is_sending = false;

            this.api.handleErrors('Transaction échoué!');
            console.log(e);
        }

    }

    resend(history: any) {
        //this.country = history.country;
        this.id = history.id;
        this.converted_amount = parseInt(history.converted_amount);
        //this.operator = history.operator;
        this.reference_paydunya = history.reference_paydunya;
        window.scrollTo({top: 0, behavior: 'smooth'});
    }


    //SERVICE

    performGetPromise(url) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPATCHPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Referer': 'https://cosna-afrique.com',
            'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.patch(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Referer': 'https://cosna-afrique.com',
            'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    goToPage(page: number) {
        this.page = page;
        if (this.page_for == 'all') {
            this.load();
        } else if (this.page_for == 'user') {
            this.search('user_id', false);
        }
    }


    performFedapayPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPUTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.put(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }
}
