import { Component, OnInit } from '@angular/core';
import {Receiver} from '../home/receiver.model';
import {ApiService} from '../service/api.service';
import {ReceiverService} from '../service/receiver.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.css']
})
export class SimulationComponent implements OnInit {

  focus;
  focus1;

  amount: string;
  operators = [];
  country = 'Cameroun';
  fees = [];
  fee = 0;
  our_fees = 0;
  their_fees = 0;
  currency = 'EUR';
  converted_amount = 0;

  alert = null;

  reference = null;
  operator = '';
  clientId = '';
  tel: any;

  constructor(
      private router: Router,
      public afs: AngularFirestore,
      private activatedRoute: ActivatedRoute,
      private route: ActivatedRoute,
      public api: ApiService,
      public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.afs
        .collection('cosna_fees')
        .valueChanges({ idField: 'dataId' })
        .subscribe((snapshots: any) => {

          console.log(snapshots);
          this.fees = snapshots;
        });
  }

  onSubmit(){
    this.fee = this.getFee()
  }

  loadOperators() {
    //console.log(this.country + 'receiver country');
    this.operators = this.api.getCountry(this.country).operators;
    //console.log(this.operators);
  }
  getFee() {
    const f = 0;
    /*this.fees.forEach((fee) => {
      //&& fee.a == this.data.receiver_operator
      if (
        parseFloat(this.amount) >= fee.min &&
        parseFloat(this.amount) <= fee.max
      ) {
        if (fee.type == "POURCENT") {
          f = parseFloat(this.amount) * (fee.montant / 100);
        } else if (fee.type == "FIXE") {
          f = fee.montant;
        }
      }
    });*/
    const fee = this.fees[0];
    console.log(fee);
    const country = this.api.getCountry(this.country);
    if (this.country == 'cm') {
      this.our_fees = parseFloat(fee.cm_fees_type == 'FIXED' ? fee.cm_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.cm_fees).toFixed(2)));
    } else if (this.country == 'ci') {
      this.our_fees = parseFloat(fee.ci_fees_type == 'FIXED' ? fee.ci_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.ci_fees).toFixed(2)));
    } else if (this.country == 'sn') {
      this.our_fees = parseFloat(fee.sn_fees_type == 'FIXED' ? fee.sn_fees : parseFloat(((parseFloat(this.amount) / 100) * fee.sn_fees).toFixed(2)));
    }
    //this.our_fees = parseFloat(((parseFloat(this.amount) / 100) * fee.montant).toFixed(2));
    this.their_fees = (country.feeType === 'percent' ? (parseFloat(this.amount) * (country.fee / 100)) : country.fee) + (parseFloat(this.amount) * ((country.payWith == 'dusupay' ? 0 : 3.5) / 100));
    return this.our_fees;
  }
}
