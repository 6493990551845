import {countryList, CountryService} from './../service/country.service';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../service/api.service';

export interface User {
  email: string;
  name: string;
  phoneNumber: string;
  country: string;
  password: string;
  numberWithCode?: string;
  customerId?: string;
  promoCode?: string;
  myPromocode?: string;
}

declare const window: any;
declare const $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  test: Date = new Date();
  focus;
  focus1;
  focus2;
  user: User = {
    name: '',
    email: '',
    phoneNumber: '',
    country: '',
    password: '',
    promoCode: '',
    myPromocode: '',
  };

  confirmPassword = '';
  countries = [];
  country: any;
  error: string;
  tel: any;
  constructor(
    private router: Router,
    public apiService: ApiService,
    public api: ApiService,
    private authservice: AuthService,
    private countryService: CountryService,
    private http: HttpClient,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const $this = this;

    this.getCountries();
    this.activeRoute.queryParams.subscribe(params => {
      this.user.promoCode = params['promoCode'];
    })
    // console.log(this.countries);
  }
  async onSubmit() {
    if (!(await this.authservice.numberExist(this.user.phoneNumber))) {
      // console.log('phone number already taken ')
      // loading.dismiss();
      this.api.handleErrors(
        'Numéro de téléphone déjà utilisé par un autre utilisateur'
      );
    } else {
      try {
        // const userCountry = this.countries.find(country=> country.alpha2Code === this.user.country)
        this.user.numberWithCode =
          this.country.alpha2Code === this.user.country
            ? '+' + this.country.callingCodes[0] + '' + this.user.phoneNumber
            : this.user.phoneNumber;
        this.user.myPromocode = Math.random().toString(20).substr(2, 6);
        const res = <any>await this.authservice.register(this.user);
        console.log('res', res);
        // let currentUser = firebase.auth().currentUser;
         //console.log(currentUser);
        // loading.dismiss();
        if (res.user) {
          const me = await this.authservice.getUser(res.user.uid);
          me.id = res.user.uid;
          delete me.password;
          localStorage.setItem('cosna_user', JSON.stringify(me));
          this.api.displayMessage('Votre compte à bien été crée.');
          this.router.navigate(['/home']);
        } else {
          this.api.handleErrors('Email invalide');
        }

        // loading.dismiss();
      } catch (err) {
        // loading.dismiss();
        // console.log('Error: ', err.message);
        this.api.handleErrors(err.message);
      }
    }
    // } else {
    //   //console.log('Bad phone');
    //   //this.api.handleErrors(await this.translate.get('Numéro de téléphone invalide').toPromise());
    // }
    /*if (!this.tel.isValidNumber()){
      this.api.handleErrors('Numéro de téléphone invalide');
      return;
  }*/
  }

  async getCountries() {

        this.countries = countryList;
        // console.log(this.countries);
        const $this = this;
        if (localStorage.getItem('country') == null) {
          // console.log("no country")
          $this.api
            .performRemoteGETCall('https://ipinfo.io?token=b47c2bd9d1e29e')
            .subscribe(async function (resp) {
              let countryCode = resp && resp.country ? resp.country : '';
              // console.log(countryCode);
              /*const ctry = await $this.api.performGetPromise(
                `http://api.countrylayer.com/v2/alpha/${countryCode}?access_key=78b1cf9b8f3005edf9afa76ad25541b7`,
                null
              );*/
              const ctry = countryList.find(country => country.alpha2Code == countryCode)
              // https://restcountries.eu/rest/v2/alpha/cm?fields=name;alpha2Code;flag;callingCodes
              localStorage.setItem('country', JSON.stringify(ctry));
              $this.country = ctry;
            });
        } else {
          // console.log("country");
          this.country = JSON.parse(localStorage.getItem('country'));
        }
  }
}
