import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../signup/signup.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuth: boolean;
  currentUser: string;

  constructor(private router: Router, public afs: AngularFirestore) {}

  register(user: User) {
    return new Promise<any>(async (resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(
          (data) => {
            this.currentUser = data.user.uid;
            this.isAuth = true;
            return firebase
              .firestore()
              .collection('users')
              .doc(data.user.uid)
              .set({...user})
              .then(
                (res) => resolve(data),
                (err) => reject(err)
              );
            /*.then(() => {

                                      localStorage.setItem('user', JSON.stringify({
                                          username: user.name,
                                          email: user.email,
                                          country: user.country,
                                          phoneNumber: user.phoneNumber,
                                          customerId: user.customerId,
                                          uid: data.user.uid
                                      }));
                                      this.router.navigate(['/home']);
                                  });*/
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async signIn(phoneNumber: string, password: string) {
    // console.log('entered');
    const user = await this.get_user_from_phoneNumber(
      phoneNumber
    ); /*.then((data) => {
      user = data;
    })*/
    // console.log('user account=> ' + user);
    return new Promise<any>((resolve, reject) => {
      if (user == null) {
        reject({ message: 'Ce numéro de téléphone n\'est lié à aucun compte' });
      } else {
        const { email } = user;
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      }
    });
  }

  get_user_from_phoneNumber(phoneNumber: string) {
    // console.log(phoneNumber);
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection('users', (ref) =>
          ref.where('phoneNumber', '==', phoneNumber)
        )
        .valueChanges({ idField: 'dataId' })
        .subscribe(
          (snapshots) => {
            // console.log(snapshots);
            if (snapshots.length > 0) {
              resolve(snapshots[0]);
            } else {
              this.afs.collection('users', ref => ref.where('phoneNumber', '==', parseInt(phoneNumber))).valueChanges({idField: 'dataId'})
                  .subscribe(snapshots_ => {
                    //console.log(snapshots);
                    if (snapshots_.length > 0) {
                      resolve(snapshots_[0]);
                    } else {
                      resolve(null);
                    }
                  }, err => {
                    reject(err);
                  });
              //resolve(null);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
    /*return new Promise<any>((resolve, reject) => {
          firebase
            .firestore()
            .collection("users")
            .where("phoneNumber", "==", phoneNumber)
            .get()
            .then(
              (snapshots) => {
                snapshots.forEach((doc) => {
                  //console.log("snapshot =>" + doc.data());
                  resolve(doc.data());
                });
              },
              (err) => reject(err)
            );
        });*/
  }

  numberExist(phoneNumber) {
    return firebase
      .firestore()
      .collection('users')
      .where('phoneNumber', '==', phoneNumber)
      .get()
      .then((snapshots) => {
        // console.log(snapshots.empty);
        return snapshots.empty;
      });
  }

  getUser(uid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection('users')
        .doc(uid)
        .valueChanges()
        .subscribe(
          (r) => {
            resolve(r);
          },
          (err) => {
            reject(err);
          }
        );
    });

    // resolve(this.snapshotChangesSubscription);
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      firebase
        .auth()
        .signOut()
        .then(() => {
          // this.firebaseService.unsubscribeOnLogOut();
          localStorage.removeItem('cosna_user');
          this.router.navigate(['/login']);
          resolve();
        })
        .catch((error) => {
          // console.log(error);
          reject();
        });
    });
  }
}
