import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  filter: string;
  value: any;
  transactions:any[] = []
  constructor(
      private activatedRoute: ActivatedRoute,
      private afs: AngularFirestore,
  ) { }

   ngOnInit(){
    this.activatedRoute.params.subscribe(async (param) =>{
      this.filter = param['filter'];
      this.value = param['value'];
      console.log(param);

      let user:any
      await this.afs.collection('users', ref => ref.where('phoneNumber', '==', this.value).limit(1))
          .valueChanges({idField:'id'})
          .subscribe(snapshot =>{
            user = snapshot[0]
            console.log(snapshot)
            if(user != null){
              console.log(user)
             // if(this.filter == 'receiver'){
                this.afs
                    .collection('transactions', ref => ref.where('user_id', '==', user.id).where('status', '==','SUCCESS'))
                    .valueChanges()
                    .subscribe( snapshot => {
                      this.transactions = snapshot
                      console.log(this.transactions)
                      this.transactions = this.transactions.filter(trans =>{
                        console.log(trans.date2)
                          return trans.date2 && trans.date2.split('/')[1] == 10
                      })
                      this.transactions.forEach(trans => {
                        trans.cosna_fee = Math.round(this.getpercentage(trans.converted_amount, 1.5))
                        trans.thier_fee = Math.round(this.getpercentage(trans.cosna_fee, 2))
                      })

                      console.log(this.transactions)
                    })
              }
           // }
          })




    })
  }

  async getUserByNumber(phoneNumber){
    let user = {}
    await this.afs.collection('users', ref => ref.where('phoneNumber', '==', phoneNumber).limit(1))
        .valueChanges({idField:'id'})
        .subscribe(snapshot =>{
          user = snapshot[0]
        })
    return user
  }

  getpercentage(value, percent){
    return (value * percent ) / 100
  }

  getTotal(){
    let total = 0;
    for (let trans of this.transactions){
      total += trans.thier_fee
    }
    return total
  }

}
