import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
    histories: any[];
    xof_balance = 0;
    xaf_balance = 0;
    users = [];
    res = [];
    show_all = true;

    is_sending = false;

    amount1 = 0;
    amount2 = 0;
    date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');

    id: any;
    country: any = 'bj';
    phoneNumber: any;
    converted_amount: any;
    operator: any = 'mtn-benin';
    reference_paydunya: any;

    gains = 0;
    transfered = 0;

    constructor(
        public afs: AngularFirestore,
        public api: ApiService,
        public datePipe: DatePipe,
    ) {
    }

    ngOnInit(): void {
        let fileds = [];
        this.load();

        this.calcGains();
        this.afs
            .collection('users', req => req.where('newVersion', '==', 'ok'),
            )
            .get()
            .toPromise().then((snapshots: any) => {
            this.xof_balance = 0;
            this.xaf_balance = 0;
            this.users = snapshots;
            snapshots.forEach((u: any) => {
                if (u.wallet) {
                    this.xof_balance += u.wallet.XOF;
                    this.xaf_balance += u.wallet.XAF;
                }
            });
            console.log('XOF = ', this.xof_balance);
            console.log('XAF = ', this.xaf_balance);
        });
        this.afs
            .collection('wallet_payin', req => req.where('user_id', '==', 'IlZgMfbmvfYODRZfJQjuZ5BuTv93').where('status', '==', 'SUCCESS'),
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                console.log(snapshots);
                let amount = 0;
                snapshots.forEach((u: any) => {
                    amount += u.converted_amount;

                });
                console.log('amount = ', amount);
            });
        this.afs
            .collection('wallet_payout', req => req.where('user_id', '==', 'IlZgMfbmvfYODRZfJQjuZ5BuTv93').where('status', '==', 'SUCCESS'),
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                console.log(snapshots);
                let amount2 = 0;
                snapshots.forEach((u: any) => {
                    amount2 += u.converted_amount;

                });
                console.log('amount2 = ', amount2);
            });



        let arr = ['TFA-TX-56oJogDv655YvB9DI6mR', 'TFA-TX-p7qnvHWVFgbTIG0atSm6', 'TFA-TX-uOR0BMDiixq02r12ScYT', 'TFA-TX-hECKA07zpKvk7SDPrfJb', 'TFA-TX-NylcdDOVFDrZpO6ktxQa', 'TFA-TX-j9TuLmqff2PaeeaUxZSW', 'TFA-TX-C3pDp99QdJJEPPH8Gj4M', 'TFA-TX-6iHaawaLSw2crol6qv6l', 'TFA-TX-ay6wCVkFChwd87LGUVYL', 'TFA-TX-LxxlLYZEcf5mfwRr7RbF'] ;
        this.res = [];
        let r = []
        arr.forEach(async a=>{
            await this.afs
                .collection('transactions', req => req.where('transaction_id', '==', a),
                )
                .valueChanges()
                .subscribe((snapshots: any) => {
                    console.log(snapshots);
                    if(snapshots.length == 0){
                        this.res.push({"id" : a, "found": false});
                        r.push(a);
                    }
                    //    this.res.push({"id" : a, "found": true});
                    //else

                }
            );
        });
        console.log(this.res);
        console.log(r);
    }

    calcGains() {
        console.log(this.date);
        this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                this.transfered = 0;
                snapshots.forEach(async (d: any) => {
                    //this.gains += parseFloat(d.converted_amount);
                    this.transfered += parseFloat(d.converted_amount);
                });
                this.gains = Math.round(0.022 * this.transfered);
                // console.log(this.histories);
            });
    }

    async load(all = true) {


        this.calcGains();
        let snapshots = await this.api.getTransactions(this.date);
        snapshots.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            d.paydunya_status = paydunya_response?.status;
        });
        this.histories = snapshots;
        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                //.where('user_id', '==', 'RdmgvgM03MO4BnSHgYVh7YRwFIw2')
                    .orderBy('timestamp', 'desc')
            )
            .valueChanges({idField: 'id'})
            .toPromise().then((snapshots) => {
                console.log(snapshots);
                snapshots.forEach(async (d: any) => {
                    const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

                    //console.log(paydunya_response);
                    //console.log(paydunya_response?.status);
                    d.paydunya_status = paydunya_response?.status;
                });
                this.histories = snapshots;
                // console.log(this.histories);
            });*/
    }

    numberWithSpaces(x) {
        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }

    setAllToSuccess() {
        this.afs
            .collection('users', (req) =>
                req.where('canSend', '==', true)
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                //this.histories = snapshots;
                // console.log(this.histories);
                snapshots.forEach(async (d: any) => {
                    await this.afs.collection('users').doc(d.id).update({
                        canSend: false
                    }).then(() => {
                        console.log('done');
                    });

                });
            });
    }

    async changestatus(id) {
        await this.afs.collection('users').doc(id).update({
            canResend: 'no'
        }).then(() => {
            console.log('done');
        });
    }


     async doDunyaDepot(id, country, phoneNumber, converted_amount, operator) {
        this.is_sending = true;

        let flutter = {
            'account_alias': phoneNumber,
            'amount': Math.round(converted_amount),
            'withdraw_mode': operator,

            /*'account_bank': this.operator,
            'account_number': this.country != "237" ? this.api.getCountry(this.country).tel+""+this.phoneNumber : this.phoneNumber.toString(),
            'amount': this.data.converted_amount,
            'narration': 'Cosna Trnsfr',
            'currency': this.api.getCountry(this.country).currency,
            'reference': new Date().getTime().toString(),
            'callback_url': 'https://webhook.site/b3e505b0-fe02-430e-a538-22bbbce8ce0d',
            'debit_currency': 'XAF',
            'beneficiary_name': 'Cosna'*/
        };
        this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', flutter).then(async res => {


            ////this.in_progress = false;
            if (res.response_code == '00') {
                this.is_sending = false;
                const updates = {
                    /*operator_code: operator,
                    converted_amount: converted_amount,
                    converted_currency: this.api.getCountry(this.country).currency,*/
                    number: '+229' + phoneNumber.toString(),
                    phoneNumber: phoneNumber.toString(),
                    status: res.response_code == '00' ? 'SUCCESS' : 'DEPOT_FAILED',
                    //reference_viewpay: new Date().getTime().toString(),
                    transaction_id: res?.transaction_id,
                    provider_ref: res?.provider_ref
                };
                await this.afs.collection('transactions').doc(id).update(updates)
                    .then(
                        result => {
                            ////console.log(result);
                            // localStorage.setItem('cosna_id', result.id);
                            ////this.in_progress = false;
                            localStorage.removeItem('ref');
                            localStorage.removeItem('level');
                            localStorage.removeItem('cosna_id');
                            localStorage.removeItem('moneygo_depot_id');
                        },
                        err => {

                        }
                    );
                alert('Transaction terminée avec succès');
            } else {
                this.is_sending = false;
                alert('Transaction échoué!');
            }


        }, async err => {
            this.is_sending = false;

            this.api.handleErrors('Transaction échoué!');
            const updates = {
                status: 'DEPOT_FAILED',
            };
            //console.log(res);
            await this.afs.collection('transactions').doc(id).update(updates)
                .then(
                    result => {
                        ////console.log(result);
                        // localStorage.setItem('cosna_id', result.id);
                        ////this.in_progress = false;
                        localStorage.removeItem('ref');
                        localStorage.removeItem('level');
                        localStorage.removeItem('cosna_id');
                        localStorage.removeItem('moneygo_depot_id');
                    },
                    err => {

                    }
                );

        });
    }

    resend(history: any) {
        //this.country = history.country;
        this.id = history.id;
        this.converted_amount = history.converted_amount;
        //this.operator = history.operator;
        this.reference_paydunya = history.reference_paydunya;
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
}
